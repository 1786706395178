









import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";

@Component({})
export default class SignWithDiscord extends Vue {
  @Prop({ type: String, required: true, default: () => 'Default Text' }) private readonly prefix!: string

  @Getter(AuthModuleGetterTypes.IS_AUTH_PROCESS_STARTED, { namespace: StoreNamespace.Auth })
  private readonly isAuthProcessStarted!: boolean

  private readonly clientId: string = process.env.VUE_APP_DISCORD_OAUTH_CLIENT_ID ? process.env.VUE_APP_DISCORD_OAUTH_CLIENT_ID : ''
  private readonly scopes: string[] = process.env.VUE_APP_DISCORD_OAUTH_SCOPES ? process.env.VUE_APP_DISCORD_OAUTH_SCOPES.split(',') : []
  private readonly redirect_uri: string = process.env.VUE_APP_DISCORD_OAUTH_REDIRECT_URI ? process.env.VUE_APP_DISCORD_OAUTH_REDIRECT_URI : ''

  private onClick () {
    window.location.replace(this.authorizeLink())
  }

  private authorizeLink() {
    return `https://discord.com/api/oauth2/authorize?client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirect_uri)}&response_type=code&scope=${encodeURIComponent(this.scopes.join(' '))}`
  }
}
