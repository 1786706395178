










































import Vue from "vue";
import {Component} from "vue-property-decorator";
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import {ISignUpWithEmailAndPasswordRequest} from "@/services/auth/types";
import {authService} from "@/services";
import {AccountFriendsModuleStoreModule, AuthStoreModule} from "@/store";
import {NotificationService} from "@/components/Notifications/notification.service";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {DefaultRedirectRoutes} from "@/router/redirect.routes";
import {AccountFriendsModuleGetterTypes} from "@/store/modules/account/friends/types";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class SignUpForm extends Vue {
  @Getter(AuthModuleGetterTypes.IS_AUTH_PROCESS_STARTED, { namespace: StoreNamespace.Auth })
  private readonly isAuthProcessStarted!: boolean

  @Getter(AccountFriendsModuleGetterTypes.INVITATION_CODE, { namespace: StoreNamespace.AccountFriends })
  private readonly invitationCode!: string | null

  private formObserver: InstanceType<typeof ValidationObserver> | null = null
  private form: ISignUpWithEmailAndPasswordRequest = {
    email: '',
    displayName: '',
    password: '',
    password_confirmation: ''
  }

  private mounted () {
    if (this.$refs.authValidationObserver) {
      this.formObserver = this.$refs.authValidationObserver as InstanceType<typeof ValidationObserver>
    }
  }

  private async onSubmitAction () {
    AuthStoreModule.startAuthProcess()
    const customTokenResult = await authService.signUpWithEmailAndPassword(this.createSignUpRequest())

    if (customTokenResult.isFailure()) {
      AuthStoreModule.stopAuthProcess()
      if (customTokenResult.error.errors) {
        this.formObserver.setErrors(customTokenResult.error.errors)
      }
      NotificationService.showErrorNotification(customTokenResult.error.message)
    } else {
      AuthStoreModule.setIsNewUser(customTokenResult.value.is_new_user)
      await AuthStoreModule.signInWithProvider(customTokenResult.value.custom_token)
      AccountFriendsModuleStoreModule.setInvitedAccount(customTokenResult.value.friend)
      NotificationService.showSuccessNotification('Successful authentication')
      this.$router.replace(DefaultRedirectRoutes.defaultMainRouteName()).then(() => {
        AuthStoreModule.stopAuthProcess()
      })
    }
  }

  private createSignUpRequest (): ISignUpWithEmailAndPasswordRequest {
    if (this.invitationCode !== null) {
      return {...this.form, ...{ invitation_code: this.invitationCode }}
    }

    return this.form
  }
}
